import * as yup from "yup";

const loginValidation = yup.object().shape({
  email: yup
    .string()
    .required("Email is required")
    .typeError("Email is required"),
  password: yup
    .string()
    .required("Password is required")
    .typeError("Password is required"),
});

const forgotPasswordValidation = yup.object().shape({
  email: yup
    .string()
    .email()
    .required("Email is required")
    .typeError("Email is required"),
});

const resetPasswordValidation = yup.object().shape({
  password: yup
    .string()
    .required("Password is required")
    .typeError("Password is required"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "passwords must match"),
});

const otpValidation = yup.object().shape({
  otp: yup.string().required("Invalid Otp").typeError("Invalid Otp"),
});

const adminProfileForm = yup.object().shape({
  first_name: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Please enter valid first name.")
    .required("First name is required")
    .typeError("First name is required"),
  last_name: yup
    .string()
    .matches(/^[a-zA-Z]+$/, "Please enter valid last name.")
    .required("Last name is required")
    .typeError("Last name is required"),
  phone_number: yup.string(),
  // .matches(new RegExp(/[+44 ]{4}[0-9]/), 'Please enter valid mobile number.')
  // .required('Phone Number is Required')
  // .typeError('Phone Number is Required'),
  age: yup
    .number()
    .min(18)
    .max(80)
    .required("Age is required")
    .typeError("Age is required"),
  gender: yup
    .string()
    .required("Gender is invalid")
    .oneOf(["male", "female", "other"]),
});

const changePersonalDetails = yup.object().shape({
  first_name: yup
    .string()
    .required("First name is required")
    .typeError("First name is required"),
  last_name: yup
    .string()
    .required("Last name is required")
    .typeError("Last name is required"),
});

const changePasswordValidate = yup.object().shape({
  old_password: yup
    .string()
    .required("Current password is required")
    .typeError("Current password is required"),
  new_password: yup
    .string()
    .required("New password is required")
    .typeError("New password is required"),
  confirm_password: yup
    .string()
    .oneOf(
      [yup.ref("new_password"), null],
      "Confirm password must be match with new password"
    )
    .typeError("Confirm password must be match with new password"),
});

const editAreaValidate = yup.object().shape({
  areaName: yup
    .string()
    .required("Area name is Required")
    .typeError("Area name is Required"),
});

const editLanguageValidate = yup.object().shape({
  language: yup
    .string()
    .required("Language name is Required")
    .typeError("Language name is Required"),
});

const editSpecialityValidate = yup.object().shape({
  speciality: yup
    .string()
    .max(50, "Max 50 characters allowed")
    .required("Speciality name is required")
    .typeError("Speciality name is required"),
});

const editMotivationalquoteValidate = yup.object().shape({
  language: yup
    .string()
    .required("Motivational Quote is Required")
    .typeError("Motivational Quote is Required"),
});

const editCountryValidate = yup.object().shape({
  language: yup
    .string()
    .required("Country is Required")
    .typeError("Country is Required"),
});

const editEmployeeRangeValidate = yup.object().shape({
  minLimit: yup
    .number()
    .integer()
    .required("Min Limit is Required")
    .typeError("Min Limit is Required"),
  maxLimit: yup
    .number()
    .integer()
    .nullable()
    .required("Max Limit is Required")
    .typeError("Max Limit is Required")
    .moreThan(yup.ref("minLimit")),
});

const editIndustryValidate = yup.object().shape({
  industry: yup
    .string()
    .required("Industry is Required")
    .typeError("Industry is Required"),
});

const editGroupeValidate = yup.object().shape({
  group: yup
    .string()
    .required("Group name is Required")
    .typeError("Group name is Required"),
});

const editProblemValidate = yup.object().shape({
  problem: yup
    .string()
    .required("Problem is Required")
    .typeError("Problem is Required"),
  problem_group_id: yup
    .mixed()
    .required("Problem Group is Required")
    .typeError("Problem Group is Required"),
});

const editMappingValidate = yup.object().shape({
  someTitle: yup
    .string()
    .required("Name is required")
    .typeError("Name is required"),
});

const titleValidate = yup.object().shape({
  title: yup
    .string()
    .trim()
    .required("Title is required")
    .typeError("Title is required"),
});

const addAdvertisementValidation = yup.object().shape({
  title: yup
    .string()
    .required("*Title is required.")
    .typeError("*Title is required."),
  law_area_id: yup
    .string()
    .required("*This field is required. Please fill the appropriate data.")
    .typeError("*This field is required. Please fill the appropriate data."),
  image_name: yup
    .mixed()
    .required("*Image is Required")
    .typeError("*Only the following formats are accepted: .jpeg, .jpg .png")
    .test(
      "type",
      "Only the following formats are accepted: .jpeg, .jpg .png",
      (value) => {
        return (
          value &&
          (value.type === "image/jpeg" ||
            value.type === "image/png" ||
            value.type === "image/jpg")
        );
      }
    ),
  redirect_url: yup
    .string()
    .required("*This is mandatory field.")
    .typeError("*This is mandatory field."),
});

const inviteAdminValidation = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
  password: yup
    .string()
    .required("password is required")
    .typeError("password is required"),
  first_name: yup
    .string()
    .required("First name is required")
    .typeError("First name is required"),
  last_name: yup
    .string()
    .required("Last name is required")
    .typeError("Last name is required"),
  role: yup.mixed().required("role is required").typeError("role is required"),
});

const inviteUserValidation = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
  password: yup
    .string()
    .required("password is required")
    .typeError("password is required"),
  first_name: yup
    .string()
    .required("First name is required")
    .typeError("First name is required"),
  last_name: yup
    .string()
    .required("Last name is required")
    .typeError("Last name is required"),
});

const inviteOrgAdminValidation = yup.object().shape({
  email: yup
    .string()
    .required("email is required")
    .email()
    .typeError("email is required"),
  org_id: yup
    .mixed()
    .required("Partner is required")
    .typeError("Partner is required"),
});

const AddOrgValidation = yup.object().shape({
  // email: yup
  //   .string()
  //   .required("email is required")
  //   .email()
  //   .typeError("email is required"),
  // org_id: yup.mixed().required("Organization is required").typeError("Organization is required"),
  org_name: yup
    .string()
    .required("Partner name is required")
    //.matches(/^[a-zA-Z]+$/, "Please enter valid Partner name.")
    .typeError("Partner name is required"),
  industry_id: yup
    .mixed()
    .required("Industry is required")
    .typeError("Industry is required"),
  allowed_domains: yup
    .string()
    .required("Allowed domains is required")
    .typeError("Allowed domains is required"),
  org_code: yup
    .string()
    .required("Partner code is required")
    .typeError("Partner code is required"),
  employee_range_id: yup
    .mixed()
    .required("Empoyee range is required")
    .typeError("Empoyee range is required"),
});

const CreateOrgValidation = yup.object().shape({
  // email: yup
  //   .string()
  //   .required("email is required")
  //   .email()
  //   .typeError("email is required"),
  // org_id: yup.mixed().required("Organization is required").typeError("Organization is required"),
  org_name: yup
    .string()
    .required("Partner name is required")
    //.matches(/^[a-zA-Z]+$/, "Please enter valid Partner name.")
    .typeError("Partner name is required"),
  industry_id: yup
    .mixed()
    .required("Industry is required")
    .typeError("Industry is required"),
  employee_range_id: yup
    .mixed()
    .required("Empoyee range is required")
    .typeError("Empoyee range is required"),
  org_type: yup
    .mixed()
    .required("Partner Access is required")
    .typeError("Partner Access is required"),
});

const adminRegistrationValidation = yup.object().shape(
  {
    first_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "Please enter valid first name.")
      .required("Name is required")
      .typeError("Name is required"),
    age: yup
      .number()
      .min(18, "Only more then 18")
      .max(80, "Only less then 99")
      .required("Age is required")
      .typeError("Age is required"),
    gender: yup
      .mixed()
      .required("Gender is required")
      .typeError("Gender is required"),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z]+$/, "Please enter valid last name.")
      .required("Last name is required")
      .typeError("Last name is required"),
    phone_number: yup
      .string()
      .nullable()
      .notRequired()
      .when("phone_number", {
        is: (value) => value?.length,
        then: (rule) =>
          rule
            .matches(/^[0-9]+$/, "Must be only digits")
            .min(10, "Enter valid number"),
      }),
    // phone_number: yup.number().min(10, "Enter valid number"),
    // .required('Phone number is required')
    // .typeError('Phone number is required'),
    password: yup
      .string()
      .required("Password is required")
      .typeError("Password is required")
      // .matches(
      //   /^[^-\s][a-zA-Z0-9_@!\s-]+$/,
      //   "Space not allowed in beginning"
      // ),
      .matches(
        /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        "Password must contain at least 8 characters, one uppercase, one number and one special case character"
      ),
    confirm_password: yup
      .string()
      .oneOf([yup.ref("password"), null], "Password must match")
      .typeError("Password must match"),
  },
  [
    // Add Cyclic deps here because when require itself
    ["phone_number", "phone_number"],
  ]
);

const addTemplateDataValidation = yup.object().shape({
  name: yup.string().required("Name is Required").typeError("Name is Required"),
  category: yup
    .string()
    .required("Category is required")
    .typeError("Category is required"),
  file_name: yup
    .mixed()
    .required("Image is required")
    .test(
      "fileSize",
      "File Size is too large, it must be less than 2 mb",
      (value) => value.size <= "2048000"
    )
    .typeError("Only the following formats are accepted: .pdf, .docx")
    .test(
      "type",
      "Only the following formats are accepted: .pdf, .docx",
      (value) => {
        return (
          value &&
          (value.type === "application/pdf" ||
            value.type === "application/msword")
        );
      }
    ),
  description: yup
    .string()
    .required("Description is required")
    .typeError("Description is required"),
});

const addFaqQuestionAnswerValidation = yup.object().shape({
  add_faq_entity_type: yup
    .string()
    .required("Please select entity type")
    .typeError("Please select entity type"),
  add_faq_category: yup
    .string()
    .required("Please select category group")
    .typeError("Please select category group"),
  add_faq_question: yup
    .string()
    .required("Please enter question")
    .typeError("Please enter question"),
  add_faq_answer: yup
    .string()
    .required("Please enter answer")
    .typeError("Please enter answer"),
  add_faq_sequence: yup
    .number()
    .required("Please enter sequence")
    .typeError("Please enter sequence"),
});

const documentSettingsValidation = yup.object().shape({
  offered_price: yup
    .number()
    .required("Offered price is required")
    .typeError("Offered price is required"),
  description: yup
    .string()
    .required("Description is required")
    .typeError("Description is required"),
  price_description: yup
    .string()
    .required("Price description is required")
    .typeError("Price description is required"),
  terms_and_condition_file_name: yup
    .string()
    .required("Upload terms & conditions is required.")
    .typeError("Only the following formats are accepted: .pdg, .doc"),
  // .test(
  //   'type',
  //   'Only the following formats are accepted: .jpeg, .jpg .bmp .png',
  //   (value) => {
  //     return (
  //       value &&
  //       (value.type === 'image/jpeg' ||
  //         value.type === 'image/bmp' ||
  //         value.type === 'image/png' ||
  //         value.type === 'image/jpg')
  //     )
  //   }
  // ),
  refund_policy_file_name: yup
    .string()
    .required("Upload refund policy is required.")
    .typeError("Only the following formats are accepted:  .pdg, .doc"),
  // .test(
  //   'type',
  //   'Only the following formats are accepted: .jpeg, .jpg .bmp .png',
  //   (value) => {
  //     return (
  //       value &&
  //       (value.type === 'image/jpeg' ||
  //         value.type === 'image/bmp' ||
  //         value.type === 'image/png' ||
  //         value.type === 'image/jpg')
  //     )
  //   }
  // ),
  policy_file_name: yup
    .string()
    .required("Upload cancellation policy is required.")
    .typeError("Only the following formats are accepted: .pdg, .doc"),
  // .test(
  //   'type',
  //   'Only the following formats are accepted: .jpeg, .jpg .bmp .png',
  //   (value) => {
  //     return (
  //       value &&
  //       (value.type === 'image/jpeg' ||
  //         value.type === 'image/bmp' ||
  //         value.type === 'image/png' ||
  //         value.type === 'image/jpg')
  //     )
  //   }
  // ),
});

const commentDropValidation = yup.object().shape({
  review_comment: yup
    .string()
    .required("comment is required")
    .typeError("comment is required"),
});

const addSubscriptionFeesValidation = yup.object().shape({
  title: yup
    .string()
    .required("Title is required.")
    .typeError("Invalid title."),
  price: yup
    .number()
    .nullable()
    .required("Price is required")
    .typeError("Please enter valid number"),
  validity_days: yup
    .number()
    .nullable()
    .required("Validity is required.")
    .typeError("Please enter validity in days"),
  description: yup
    .string()
    .required("Description is required")
    .typeError("Description is required"),
});

const meetingSettingsValidation = yup.object().shape({
  first_appointment_reminder: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed"),
    // .min(30, "Min required is 30 mins"),
  }),
  second_appointment_reminder: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed"),
    // .min(30, "Min required is 30 mins"),
  }),
  third_appointment_reminder: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed"),
    // .min(30, "Min required is 30 mins"),
  }),
  reschedule_appointment_threshold: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed")
      .min(120, "Min required is 120 mins"),
  }),
  respond_to_reschedule_request_threshold: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed")
      .min(90, "Min required is 90 mins"),
  }),
  cancel_appointment_threshold: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed")
      .min(120, "Min required is 120 mins"),
  }),
  join_appointment_before_minutes: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed")
      .min(0, "Min required is 0 mins")
      .max(15, "Max allowed is 15 mins"),
  }),
  lesson_attendance_threshold: yup.object({
    value: yup
      .number()
      // .test(
      //   "Is positive?",
      //   "The number must be greater than 0",
      //   (value) => value >= 0
      // )
      .integer("Only integers allowed")
      .min(0, "Min required is 0%")
      .max(100, "Max allowed is 100%"),
  }),
});

const suspensionReason = yup.object().shape({
  suspend_reason: yup
    .string()
    // .matches(
    //   /^[a-zA-Z.!-?\+, ]+$/,
    //   "Please enter a valid reason. Only alphabetic characters and few special characters are allowed."
    // )
    // .matches(
    //   /^[^-\s][a-zA-Z0-9_.!-?\+\s-]+$/,
    //   "Please enter a valid reason. Only alphabetic characters and few special characters are allowed."
    // )
    .required("Reason is required")
    .typeError("Reason is required"),
});

const createCoupon = yup.object().shape({
  coupon_code: yup
    .string()
    .required("Coupon code is required")
    .typeError("Coupon code is required"),
  duration: yup
    .string()
    .required("Duration is required")
    .typeError("Duration is required"),
  durationInMonths: yup
    .number()
    .integer("Only integers allowed")
    .typeError("duration in months must be number")
    .min(1, "Min required is 1"),
  // durationInMonths: yup.number()
  //   .when('duration', {
  //     is: (duration) => duration == "repeating",
  //     then: yup.string().required("Duration in months is required").typeError("Duration in months is required").min(1, "Min required is 1"),
  //     otherwise: yup.string().min(1, "Min required is 1")
  //   }),
  // .max(100, "Max allowed is 100%"),
  applicable_to: yup
    .string()
    .required("Applicable to is required")
    .typeError("Applicable to is required"),
  // organisation: yup.string(),
  organisation: yup
    .string()
    .nullable()
    .notRequired()
    .when("applicable_to", {
      is: (value) => value == "specific_org",
      then: (rule) =>
        rule.required("Partner is required").typeError("Partner is required"),
    }),
  // .when(['applicable_to'=="specific_org"], {
  //   is: true,
  // })
  // .required("Organisation is required")
  // .typeError("Organisation is required"),
  allowed_plan_redemption: yup
    .string()
    .required("Allowed Plan Redeemption is required")
    .typeError("Allowed Plan Redeemption is required"),
  percentOff: yup
    .number()
    .required("Percent Off is required")
    .typeError("Percent Off is required")
    .min(1, "Min required is 1")
    .max(100, "Max percent off should be less than 100"),
  maxRedemptions: yup
    .number()
    .required("Max redemptions is required")
    .typeError("Max redemptions is required")
    .integer("Only integers allowed")
    .min(1, "Min required is 1"),
  expiryDate: yup
    .string()
    .required("Expiry date is required")
    .typeError("Expiry date is required"),
});

const managePolicyValidate = yup.object().shape({
  // role: yup
  //   .string()
  //   .trim()
  //   .required("Role name is required")
  //   .typeError("Role name is required"),
  policy: yup
    .array()
    .min(1, "Select atleast 1 policy")
    // .max(3, "Max 3 category allowed")
    .required("Policy is required")
    .typeError("Policy is required"),
});

const manageRatingTagsValidate = yup.object().shape({
  // role: yup
  //   .string()
  //   .trim()
  //   .required("Role name is required")
  //   .typeError("Role name is required"),
  tag: yup
    .array()
    .min(1, "Select atleast 1 tag")
    // .max(3, "Max 3 category allowed")
    .required("Tag is required")
    .typeError("Tag is required"),
});

const publisBlogValidation = yup.object().shape({
  seo_description: yup
    .string()
    .required("Seo Description is required")
    .typeError("Seo Description is required"),
  seo_tags: yup
    .array()
    .min(1, "Add atleast 1 seo tag")
    // .max(3, "Max 3 category allowed")
    .required("Seo Tag is required")
    .typeError("Seo Tag is required"),
  title_as_blog: yup
    .string()
    .required("Title as a blog is required")
    .typeError("Title as a blog is required"),
  expert_public_bio: yup
    .string()
    .required("Expert public bio is required")
    .typeError("Expert public bio is required"),
  expert_linkedin_url: yup
    .string()
    // .matches(/^http[s]?:\/\/www\.linkedin\.com\/(in|pub|public-profile\/in|public-profile\/pub)\/([\w]{6}-[\w]{1,}-[\w]+)$/gm, "Please enter valid linkedin url.")
    .matches(
      /^http[s]?:\/\/www\.linkedin\.com\/([a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<][a-zA-Z0-9/~\-_,&=\?\.;]+[^\.,\s<])$/gm,
      "Please enter valid linkedin url."
    )
    .required("Expert linkedin url is required")
    .typeError("Expert linkedin url is required"),
});

const addReelValidation = yup.object().shape({
  video: yup
    .string()
    .required("Clip is required")
    .typeError("Clip is required"),
  sentiment_ids: yup
    .array()
    .min(1, "Add atleast 1 category")
    // .max(3, "Max 3 category allowed")
    .required("Category is required")
    .typeError("Category is required"),
  caption: yup
    .string()
    .required("Caption is required")
    .max(100, "Max 100 characters allowed")
    .typeError("Caption is required"),
});

const messageValidation = yup.object().shape({
  message: yup
    .string()
    .trim()
    // .max(100, "Max 100 character allowed ")
    .required("Please enter the message")
    .typeError("Please enter the message"),
});

let outside = true;
const addpushNotificationValidation = yup.object().shape({
  text: yup
    .string()
    .trim()
    .required("Please enter the text")
    .typeError("Please enter the text"),
  entity_type: yup
    .string()
    .trim()
    .required("Please select user type")
    .typeError("Please select user type"),
  scheduleNotification: yup.bool(),
  scheduleDate: yup
    .string()
    .nullable()
    .notRequired()
    .when("scheduleNotification", {
      is: (value) => value == outside,
      then: (rule) =>
        rule
          .nullable(false)
          .required("Date is required")
          .typeError("Date is required"),
    }),
  scheduleTime: yup
    .string()
    .nullable()
    .notRequired()
    .when("scheduleNotification", {
      is: (value) => value == outside,
      then: (rule) =>
        rule
          .nullable(false)
          .required("Time is required")
          .typeError("Time is required"),
    }),
});

export {
  AddOrgValidation, changePersonalDetails,
  CreateOrgValidation, addAdvertisementValidation, addFaqQuestionAnswerValidation, addReelValidation, addSubscriptionFeesValidation, addTemplateDataValidation, addpushNotificationValidation, adminProfileForm, adminRegistrationValidation, changePasswordValidate, commentDropValidation, createCoupon, documentSettingsValidation, editAreaValidate, editCountryValidate, editEmployeeRangeValidate, editGroupeValidate, editIndustryValidate, editLanguageValidate, editMappingValidate, editMotivationalquoteValidate, editProblemValidate, editSpecialityValidate, forgotPasswordValidation, inviteUserValidation, inviteAdminValidation, inviteOrgAdminValidation, loginValidation, managePolicyValidate,
  manageRatingTagsValidate, meetingSettingsValidation, messageValidation, otpValidation, publisBlogValidation, resetPasswordValidation, suspensionReason, titleValidate
};
