import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { FormHelperText } from "@mui/material";
import { Box } from "@material-ui/core";
import { useEffect } from "react";

const CreateRankModal = ({
  setCreate,
  rank,
  setRank,
  title, setTitle,
  createRank,
  currentRow,
  setCurrentRow,
}) => {
  const [errors, setErrors] = React.useState(null);
  const handleChange = (value) => {
    setErrors(null);
    setRank(value);
  };

  const handleClose = () => {
    setCreate(false);
    setCurrentRow(null);
  };

  const handleSave = () => {
    if (!rank) {
      setErrors("Please enter message");
    } else {
      if (currentRow) {
        createRank("update");
      } else {
        createRank("create");
      }
    }
  };

  useEffect(() => {
    if (!currentRow) {
      setRank("");
    }
  }, []);

  return (
    <React.Fragment>
      <Dialog open={true} fullWidth onClose={handleClose}>
        <DialogTitle>
          {currentRow ? "Edit Rank" : "Create New Announcement"}
        </DialogTitle>
        <DialogContent>
          <Box>
            <span>Title</span>
            <TextField
              variant="outlined"
              autoFocus
              // error={errors}
              id="title"
              value={title}
              placeholder="Enter here"
              fullWidth
              onChange={(e) => {
                setTitle(e.target.value)
              }}
              style={{ marginTop: ".5rem" }}
            />
            <span>Announcement Message</span>
            <TextField
              variant="outlined"
              autoFocus
              error={errors}
              id="name"
              value={rank}
              placeholder="Enter here"
              fullWidth
              multiline
              rows={5}
              onChange={(e) => handleChange(e.target.value)}
              style={{ marginTop: ".5rem" }}
            />
          </Box>
          <FormHelperText style={{ color: "red" }}>{errors}</FormHelperText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            onClick={handleSave}
            style={{ background: "black", color: "white" }}
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default CreateRankModal;
