import { Box } from "@material-ui/core";
import { FormHelperText, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import moment from "moment";
import { List, ListItem, Avatar, ListItemAvatar, ListItemText } from "@mui/material"
import * as React from "react";
import { useEffect } from "react";

const CreateRankModal = ({
    setCreate,
    rank,
    view,
    setRank,
    setAllComments,
    setView,
    setComment,
    createRank,
    currentRow,
    allComments,
    setCurrentRow,
}) => {
    const [errors, setErrors] = React.useState(null);
    const handleChange = (value) => {
        setErrors(null);
        setRank(value);
    };

    const handleClose = () => {
        setCreate(false);
        setCurrentRow(null);
        setComment(false)
        setAllComments([]);
        setView(false)

    };

    const handleSave = () => {
        if (!rank) {
            setErrors("Please enter message");
        } else {
            if (currentRow) {
                createRank("update");
            } else {
                createRank("create");
            }
        }
    };

    useEffect(() => {
        if (!currentRow) {
            setRank("");
        }
    }, []);

    return (
        // <React.Fragment>
        <Dialog open={true} maxWidth="xs" fullWidth onClose={handleClose}>
            <DialogTitle>
                {!view ? "Add Comment" : "See Comments"}

            </DialogTitle>
            <DialogContent>
                <Box>
                    {
                        !view ? (
                            <TextField
                                variant="outlined"
                                autoFocus
                                error={errors}
                                id="name"
                                value={rank}
                                placeholder="Enter here"
                                multiline
                                rows={5}
                                onChange={(e) => handleChange(e.target.value)}
                                style={{ marginTop: ".5rem", width: '100%' }}
                            />
                        ) : (
                            <div>

                                <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                                    {
                                        allComments.map((item, index) => (
                                            <ListItem>
                                                <ListItemAvatar>
                                                    <Avatar>
                                                        {/* <ImageIcon /> */}
                                                    </Avatar>
                                                </ListItemAvatar>
                                                {/* <ListItemText
                                                    primary={item.comment}
                                                    secondary={moment.unix(item.created_at).format("Do MMM YYYY")}
                                                /> */}
                                                <ListItemText
                                                    primary={item.admin_first_name + " " + item.admin_last_name}
                                                    secondary={
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                variant="body2"
                                                                sx={{ color: 'text.primary', display: 'inline' }}
                                                            >
                                                                {moment.unix(item.created_at).format("Do MMM YYYY")}
                                                            </Typography>
                                                            {" —"}{" " + item.comment}

                                                        </React.Fragment>
                                                    }
                                                />
                                            </ListItem>
                                        ))
                                    }

                                </List>
                            </div>
                        )
                    }
                </Box>
                <FormHelperText style={{ color: "red" }}>{errors}</FormHelperText>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {
                    !view && (
                        <Button
                            onClick={handleSave}
                            style={{ background: "black", color: "white" }}
                        >
                            Send
                        </Button>
                    )
                }
            </DialogActions>
        </Dialog>
        // </React.Fragment>
    );
};

export default CreateRankModal;
