import React, { useEffect, useState } from "react";
import Card from "../../components/MidOverview/Card";
import UserGraph from "../../components/MidOverview/UserGraph";
import GroupGraph from "../../components/MidOverview/GroupGraph";
import { Grid, Typography } from "@mui/material";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import { useAuth } from "../../providers/AuthProvider";
import useCancelRequest from "../../hooks/useCancelToken";
import { useSnackbar } from "notistack";
import moment from "moment";
import { Backdrop, CircularProgress } from "@material-ui/core";
import { isMobile } from "react-device-detect";
import Cards from "./Cards"
import UserRequests from "./UserRequests"
import PendingTickets from "./PendingTickets"


const MidOverview = () => {
  const auth = useAuth();
  const apiSource = useCancelRequest();
  const getRankListApiStatus = useCallbackStatus();
  const notification = useSnackbar();
  const [userData, setUserData] = useState([]);
  const [members, setMembers] = useState([]);
  const [groups, setGroups] = useState([])
  const [broadcast, setBroadcast] = useState([]);
  const [duty, setDuty] = useState([])
  const [groupData, setGroupData] = useState([]);
  const [other_data, setOtherData] = useState([]);

  useEffect(() => {
    getDashoardMetrics();
  }, []);

  const getDashoardMetrics = async (token, refresh) => {
    // set to 12:00 am today
    const startDate = moment().subtract(30, "days").startOf("day");

    // The end date for the last 30 days would be yesterday (considering 'today' is not complete)
    const endDate = moment().subtract(1, "days").endOf("day");

    let apiBody = {
      name: ["user_count", "group_count", "other_count", "total_user_count", "total_group_count", "total_announcement_count", "total_duty_card_count"],
      start_date: Math.floor(startDate.valueOf() / 1000),
      end_date: Math.floor(endDate.valueOf() / 1000),
    };
    console.log("ehllo");
    console.log(apiBody);
    await getRankListApiStatus.run(
      apiClient("POST", "admin", "getdashboardmetrics", {
        body: {
          ...apiBody,
        },
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          console.log("data from api announcement", result);
          setUserData(result.content.user_data);
          setGroupData(result.content.group_data);
          setOtherData(result.content.other_data);
          setMembers(result.content.total_user_count);
          setGroups(result.content.total_group_count)
          setBroadcast(result.content.total_announcement_count)
          setDuty(result.content.total_duty_card_count)
        })
        .catch((err) => {
          if (err && err.code === 403) {
            auth.logout();
          } else {
            notification.enqueueSnackbar(err.message, {
              variant: "err",
              autoHideDuration: 2000,
            });
          }
        })
    );
  };
  return (
    <>
      <Backdrop
        open={getRankListApiStatus.isPending}
      // onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>

        {other_data.length > 0 ? (
          <>
            <Typography style={{ color: '#202224', fontWeight: '700', fontSize: '32px', marginBottom: '20px' }}>
              Dashboard Overview
            </Typography>
            <div style={{ marginBottom: "20px" }}>
              <Cards members={members} groups={groups} duty={duty} broadcast={broadcast} />
            </div>
            <Grid container spacing={2}>

              <Grid item sm={12}>
                {isMobile ? (
                  <div style={{
                    width: "60%",
                    height: '90%',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    WebkitOverflowScrolling: 'touch',
                    display: 'inline-block',
                    zoom: 1
                  }}>
                    <UserGraph data={userData} />

                  </div>
                ) : (

                  <UserGraph data={userData} />

                )}
              </Grid>
              <Grid item sm={12}>
                {isMobile ? (
                  <div style={{
                    width: "60%",
                    height: '90%',
                    whiteSpace: 'nowrap',
                    position: 'relative',
                    overflowX: 'scroll',
                    overflowY: 'hidden',
                    WebkitOverflowScrolling: 'touch',
                    display: 'inline-block',
                    zoom: 1
                  }}>
                    <GroupGraph data={groupData} />

                  </div>
                ) : (

                  <GroupGraph data={groupData} />

                )}
              </Grid>
              <Grid item sm={12}>
                <UserRequests data={other_data} />
              </Grid>
              <Grid item sm={12}>
                <PendingTickets data={other_data} />
              </Grid>
              {/* <Grid item sm={12}>
                <Card data={other_data} />
              </Grid> */}


            </Grid>
          </>
        ) : null}
      </div>
    </>
  );
};

export default MidOverview;
