import {
    Menu,
    MenuItem,
    Select,
    TableCell,
    Typography,
    IconButton,
    Button,
    InputLabel,
    FormHelperText,
    DialogContentText,
    CircularProgress,
} from "@mui/material";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Paper, TableRow, TextField } from "@mui/material";
import { inviteUserValidation } from "../../validations/login-validate";
import Header from "../../layouts/Header"
import React, { useCallback, useEffect, useState } from "react";
import Datatables from "../../components/Datatables";
import useGeneralStyle from "../../assets/css/general";
import SearchIcon from "@mui/icons-material/Search";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import Modal from "../../MuiComponent/Modal";
import { useFormik } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import useCancelRequest from "../../hooks/useCancelToken";
import { useSnackbar } from "notistack";
import { apiClient } from "../../config/apiClient";
import PopupState, { bindTrigger, bindMenu } from "material-ui-popup-state";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { STATUS_COLOR } from "../../config/constants";
import _ from "lodash";

import moment from "moment";
import { Box } from "@mui/system";
import SuspendAdminModal from "./SuspendAdminModal";
import { useAuth } from "../../providers/AuthProvider";

const tableHeader = [
    { id: "CreatedAt", align: "left", label: "CREATED AT", minWidth: 170 },
    { id: "FirstName", align: "left", label: "FIRST NAME", minWidth: 170 },
    { id: "LastName", align: "left", label: "LAST NAME", minWidth: 170 },
    // { id: "RoleName", align: "left", label: "ROLE NAME", minWidth: 170 },
    { id: "Email", align: "left", label: "EMAIL", minWidth: 170 },
    { id: "Status", align: "left", label: "STATUS", minWidth: 170 },
];

// let diffTableHeader = [...tableHeader];
// diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
    formControl: {
        margin: theme2.spacing(1),
        minWidth: 160,
        color: "white",
        borderBottom: "none",
    },
    selectEmpty: {
        marginTop: theme2.spacing(2),
    },
    statusBox: {
        width: "150px",
        borderRadius: "5px",
        padding: "5px",
        textAlign: "center",
        fontWeight: "bold",
    },
    icon: {
        fill: "white",
    },
    root: {
        width: "100%",
        overflowX: "auto",
        // marginTop: '25px',
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    suspendedStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #fd6868",
        backgroundColor: "#ffc3c3",
        color: "#e64848",
        fontWeight: "bold",
    },
    pendingStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #eab20a",
        backgroundColor: "#ffeeb5",
        color: "#eab20a",
        fontWeight: "bold",
    },
    registeredStatus: {
        borderRadius: "5px",
        padding: "5px 30px",
        border: "1px solid #6ac358",
        backgroundColor: "#c3f4ba",
        color: "#6ac358",
        fontWeight: "bold",
    },
    container: {
        height: "440px",
        width: "100%",
    },
    activeFilter: {
        // backgroundColor: theme2.palette.primary.light,
        borderBottom: "2px solid white ",
        borderRadius: "0px",
        color: "white",
        fontWeight: 600,
    },
    icon: {
        fill: "#FFFFFF",
    },
    carddelModal: {
        width: "500px",
        borderRadius: "12px",
        backgroundColor: "#1b1f3b",
        padding: "1rem",
        position: "relative",

        [theme2.breakpoints.down("sm")]: {
            width: "90vw !important",
        },

        "&:focus-visible": {
            outline: "none",
        },
    },
    profileData: {
        color: "white",
        paddingTop: "2px",
        fontSize: "16px",
        // fontWeight: 600,
    },
    profileHeading: {
        fontSize: "12px",
        // fontWeight: 600,
        color: "#B0B2B6",
        textAlign: "left",
    },

    parentofappointmentcontent: {
        [theme2.breakpoints.down("md")]: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
        },
    },
    bottombox: {
        [theme2.breakpoints.down("xs")]: {
            marginLeft: "0 !important",
            display: "flex",
            justifyContent: "flex-start !important",
            alignItems: "flex-start !important",
        },
    },
    doublerarrow: {
        [theme2.breakpoints.down("xs")]: {
            transform: "rotate(90deg)",
            margin: "1rem !important",
        },
    },

    tabsContainer: {
        backgroundColor: "#fff",
        margin: "20px 20px 0px 20px",
        height: "44px",
        [theme2.breakpoints.down("md")]: {
            width: "95%",
            overflow: "scroll",
        },
        [theme2.breakpoints.down("sm")]: {
            width: "88%",
            overflow: "scroll",
        },
        "&::-webkit-scrollbar": {
            height: "0",
        },
    },
}));

const AdminUsersTable = () => {
    const globalClasses = useGeneralStyle();
    const classes = useStyles();
    const getAdminListStatus = useCallbackStatus();
    const getUpdateAdminStatus = useCallbackStatus();
    const resetPasswordApiStatus = useCallbackStatus();

    const inviteAdminStatus = useCallbackStatus();

    const apiSource = useCancelRequest();
    const notification = useSnackbar();
    const [search, setSearchKey] = useState("");
    const [adminListData, setAdminListData] = useState([]);
    const [currentStatus, setCurrentStatus] = useState("approved");
    const [filter, setFilter] = useState("approved");
    const [modal, setModal] = useState(false);
    const [roleList, setRoleList] = useState([]);
    const [adminComment, setAdminComment] = useState("");
    const [reason, setReason] = useState(false);
    const [rowId, setRowId] = useState(null);
    const [role_id, setRoleId] = useState(null);
    const [hasMore, setHasMore] = useState(false);
    const [totalPages, setTotalPages] = useState(0);
    const auth = useAuth();
    const {
        values,
        touched,
        errors,
        setValues,
        handleBlur,
        submitForm,
        handleChange,
        resetForm,
    } = useFormik({
        initialValues: {
            email: "",
            name: "",
            // last_name: "",
            // role: null,
            // password: null,
        },
        validationSchema: inviteUserValidation,
        onSubmit: () => {
            // console.table(values);
            inviteAdmin(values);
            // setValues(null);
        },
    });
    let resetPassword = async (email) => {
        let apiBody = {
            email: email
        }
        console.log(apiBody)

        try {
            const result = await resetPasswordApiStatus.run(
                apiClient("POST", "admin", "resetpassword", {
                    body: apiBody,
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );



            notification.enqueueSnackbar("Reset Password Link sent!", {
                variant: "success",
            });


        } catch (error) {
            console.log("Reset Password Component Error: ", error);

            notification.enqueueSnackbar(error.message, {
                variant: "error",
            });
        }
    };
    let inviteAdmin = async (values) => {
        await inviteAdminStatus.run(
            apiClient("POST", "admin", "inviteuser", {
                body: {
                    // role_id: values.role,
                    // action: "create",
                    name: values.first_name,
                    // last_name: values.last_name,
                    email: values.email,
                    // password: values.password,
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log("=======", data);
                    setModal(!modal);
                    notification.enqueueSnackbar("Admin Invited Successfully", {
                        variant: "success",
                    });
                    resetForm();
                    getAdminList(null, true);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const updateAdminList = async (Id, action) => {
        console.log("id adminlist", Id);

        let apiBody = {};

        // const updateAdminList = async (Id, action) => {
        //   let apiBody = {};
        apiBody = {
            action_user_type: "user",
            action_user_id: Id,
            action: action,
            comment: adminComment,
        };

        console.log("updateAdminList apibody", apiBody);

        if (currentStatus !== "suspended") {
            delete apiBody.comment;
        }

        try {
            const res = await getUpdateAdminStatus.run(
                apiClient("POST", "admin", "userstatusactions", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data },
            } = res;

            console.log("check updatedData", data);

            getAdminList(null, true);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();
                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    const getAdminList = async (token, refresh = false) => {
        // let apiBody = {
        //     pagination_required: true,
        //     next_token: token,
        // };

        var apiBody = {
            search_string: search,
            status: filter,
            pagination_required: true,
            next_token: token,
            search_fields: ["first_name", "last_name", "email"],
        };

        if (apiBody.search_string === "") {
            delete apiBody.search_string;
        }

        try {
            const res = await getAdminListStatus.run(
                apiClient("POST", "user", "getuserbyfilter", {
                    body: { ...apiBody },
                    shouldUseDefaultToken: false,
                    cancelToken: apiSource.token,
                    enableLogging: true,
                })
            );

            const {
                content: { data, has_more, next_token },
            } = res;
            console.log(data)
            if (refresh) setAdminListData([...data]);
            else {
                setAdminListData([...adminListData, ...data]);
            }
            setHasMore(has_more);
            setTotalPages(next_token);
        } catch (err) {
            if (err && err.code === 403) {
                // logout();

                console.log("error", err);
            } else {
                notification.enqueueSnackbar(err.message, {
                    variant: "err",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    let getAdminRoles = async () => {
        await getAdminListStatus.run(
            apiClient("POST", "common", "manageentities", {
                body: {
                    action: "list",
                    entity_type: "role",
                },
                shouldUseDefaultToken: false,
                cancelToken: apiSource.token,
                enableLogging: true,
            })
                .then((result) => {
                    const {
                        content: { data },
                    } = result;
                    console.log(data);
                    setRoleList(data);
                })
                .catch((error) => {
                    notification.enqueueSnackbar(error.message, {
                        variant: "error",
                    });
                })
        );
    };
    const handleSuspendAction = (rowId) => {
        setRowId(rowId);
        setReason(true);
    };

    useEffect(() => {
        getAdminList(null, true);
    }, [currentStatus, filter]);
    useEffect(() => {
        getAdminRoles();
    }, []);

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            getAdminList(null, true);
        }, 300);

        return () => clearTimeout(delayDebounceFn);
    }, [search]);

    const ModalHeader = () => {
        return (
            <>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    style={{ fontSize: "14px" }}
                >
                    <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
                        Invite New User
                    </Box>
                    <Button
                        style={{ position: "absolute", right: "20px" }}
                        onClick={() => {
                            setModal(!modal);
                            setValues(null);
                            resetForm();
                        }}
                    >
                        <CloseIcon size="large" style={{ color: "white" }} />
                    </Button>
                    {/* <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
              To: {userObj.email}
            </Box> */}
                </Box>
            </>
        );
    };

    return (
        <>
            <Header />
            {reason ? (
                <SuspendAdminModal
                    setReason={setReason}
                    setAdminComment={setAdminComment}
                    updateAdminList={() => updateAdminList(rowId, "suspend")}
                    adminComment={adminComment}
                    setCurrentStatus={setCurrentStatus}
                />
            ) : null}
            <Modal
                headerComponent={ModalHeader}
                status={modal}
                loading={inviteAdminStatus.isPending}
                // height="345px"
                // width="550px"
                closeBtnText="Cancel"
                saveBtnText="Invite"
                saveHandler={submitForm}
                closeHandler={() => {
                    setModal(!modal);
                    setValues(null);
                    resetForm();
                }}
            >
                <DialogContentText>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Box display="flex" flexDirection="column">
                                <>
                                    <InputLabel className={globalClasses.inputLabel}>
                                        First Name*
                                    </InputLabel>
                                    <TextField
                                        id="first_name"
                                        name="first_name"
                                        error={touched.first_name && errors.first_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                        variant="outlined"
                                        placeholder="Write here..."
                                        className={globalClasses.selectInput}
                                    />

                                    {touched.first_name && errors.first_name && (
                                        <FormHelperText error variant="outlined">
                                            {touched.first_name && errors.first_name}
                                        </FormHelperText>
                                    )}
                                    {/* <InputLabel className={globalClasses.inputLabel}>
                                        Last Name*
                                    </InputLabel>
                                    <TextField
                                        id="last_name"
                                        name="last_name"
                                        error={touched.last_name && errors.last_name}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                        variant="outlined"
                                        placeholder="Write here..."
                                        className={globalClasses.selectInput}
                                    />
                                    {touched.last_name && errors.last_name && (
                                        <FormHelperText error variant="outlined">
                                            {touched.last_name && errors.last_name}
                                        </FormHelperText>
                                    )} */}
                                    <InputLabel className={globalClasses.inputLabel}>
                                        Email ID*
                                    </InputLabel>
                                    <TextField
                                        id="email"
                                        name="email"
                                        error={touched.email && errors.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                        variant="outlined"
                                        placeholder="Write here..."
                                        className={globalClasses.selectInput}
                                    />
                                    {touched.email && errors.email && (
                                        <FormHelperText error variant="outlined">
                                            {touched.email && errors.email}
                                        </FormHelperText>
                                    )}
                                    {/* <InputLabel className={globalClasses.inputLabel}>
                                        Password*
                                    </InputLabel>
                                    <TextField
                                        id="password"
                                        name="password"
                                        error={touched.password && errors.password}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.password}
                                        variant="outlined"
                                        placeholder="Write here..."
                                        type="password"
                                        className={globalClasses.selectInput}
                                    />
                                    {touched.password && errors.password && (
                                        <FormHelperText error variant="outlined">
                                            {touched.password && errors.password}
                                        </FormHelperText>
                                    )} */}
                                    {/* <InputLabel className={globalClasses.inputLabel}>
                                        Role*
                                    </InputLabel>
                                    <Select
                                        id="role"
                                        name="role"
                                        error={touched.role && errors.role}
                                        helperText={touched.role && errors.role}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className={globalClasses.selectInput}
                                        variant="outlined"
                                        inputProps={{
                                            classes: {
                                                icon: classes.icon,
                                            },
                                        }}
                                        value={values.role === null ? "none" : values.role}
                                    >
                                        <MenuItem disabled default selected value="none">
                                            Select here
                                        </MenuItem>

                                        {roleList && roleList.length > 0
                                            ? roleList.map((item) => (
                                                <MenuItem key={item.id} value={item.id}>
                                                    {item.name}
                                                </MenuItem>
                                            ))
                                            : null}
                                    </Select>
                                    {touched.role && errors.role && (
                                        <FormHelperText error variant="outlined">
                                            {touched.role && errors.role}
                                        </FormHelperText>
                                    )} */}
                                </>
                            </Box>
                        </Grid>
                    </Grid>
                </DialogContentText>
            </Modal>
            <Grid container>
                <Grid
                    container
                    style={{ backgroundColor: "white", paddingLeft: "20px" }}
                    className="ChangeRequestsContainer"
                >
                    <Grid
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={4}
                        md={4}
                        lg={4}
                    >
                        <TextField
                            onChange={(e) => setSearchKey(e.target.value)}
                            variant="outlined"
                            style={{ backgroundColor: "#F5F5F7", width: "400px" }}
                            inputProps={{
                                style: { padding: "12px" },
                            }}
                            InputProps={{
                                startAdornment: (
                                    <SearchIcon
                                        variant="filled"
                                        fontSize="small"
                                        style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                                    />
                                ),
                                className: "inputFont",
                            }}
                            placeholder="Search admin by name or email"
                        />
                    </Grid>

                    <Grid
                        style={{
                            display: "flex",
                            // alignItems: "center",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={5}
                        md={5}
                        lg={5}
                    >
                        <Select
                            inputProps={{
                                classes: {
                                    icon: classes.icon,
                                },
                            }}
                            variant="outlined"
                            value={filter}
                            onChange={(e) => {
                                setFilter(e.target.value);
                            }}
                            style={{
                                marginLeft: "10px",
                                paddingRight: "20px",
                                backgroundColor: "#F5F5F7",
                            }}
                            className={globalClasses.selectInput}
                        >
                            <MenuItem key="approved" value="approved">
                                Approved
                            </MenuItem>
                            <MenuItem key="suspended" value="suspended">
                                Suspended
                            </MenuItem>
                        </Select>
                    </Grid>
                    <Grid
                        style={{
                            display: "flex",
                            alignItems: "center",
                            padding: "10px 0px",
                        }}
                        item
                        xs={11}
                        sm={2}
                        md={2}
                        lg={2}
                    >
                        <Button
                            onClick={(e) => {
                                e.preventDefault();
                                setModal(!modal);
                            }}
                            variant="contained"
                            disableElevation
                            style={{ width: "40%", backgroundColor: "#4379EE" }}
                        >
                            <Typography style={{ color: "white" }}>invite</Typography>
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} style={{ paddingTop: "20px" }}>
                    <Paper className={classes.root}>
                        <Datatables
                            data={adminListData}
                            loading={getAdminListStatus.isPending}
                            getDataList={(token) => getAdminList(token, false)}
                            hasMore={hasMore}
                            nextToken={totalPages}
                            tableHeader={tableHeader}
                            orderByKey="name"
                            isEmpty={adminListData.length === 0}
                        >
                            {adminListData.map((row, index) => {
                                return (
                                    <TableRow key={index} hover>
                                        <TableCell>
                                            <Typography>
                                                {row.created_at
                                                    ? moment.unix(row.created_at).format("Do MMM YYYY")
                                                    : "-"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {row.first_name ? row.first_name : "-"}

                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Typography>
                                                {row.last_name ? row.last_name : "-"}

                                            </Typography>
                                        </TableCell>
                                        {/* <TableCell>
                        <Typography>
                          {row.role_name || "-"}
  
                        </Typography>
                      </TableCell> */}
                                        <TableCell>
                                            <Typography>
                                                {row.email || "-"}
                                            </Typography>
                                        </TableCell>
                                        <TableCell>
                                            {auth.getUserId() === row.id ? (
                                                <Box
                                                    style={{
                                                        backgroundColor: STATUS_COLOR[row.status],
                                                    }}
                                                    className={classes.statusBox}
                                                >
                                                    <Typography style={{ color: "white" }}>
                                                        {_.upperCase(
                                                            row.status === "approved"
                                                                ? "Approved"
                                                                : row.status === "suspended" && "Suspended"
                                                        )}
                                                    </Typography>
                                                </Box>
                                            ) : (
                                                <Box display="flex" alignItems="center">
                                                    <Box
                                                        style={{
                                                            backgroundColor: STATUS_COLOR[row.status],
                                                        }}
                                                        className={classes.statusBox}
                                                    >
                                                        <Typography style={{ color: "white" }}>
                                                            {_.upperCase(
                                                                row.status === "approved"
                                                                    ? "Approved"
                                                                    : row.status === "suspended" && "Suspended"
                                                            )}
                                                        </Typography>
                                                    </Box>
                                                    <Box>
                                                        <PopupState
                                                            variant="popover"
                                                            popupId="demo-popup-menu"
                                                        >
                                                            {(popupState) => (
                                                                <React.Fragment>
                                                                    <IconButton
                                                                        size="small"
                                                                        {...bindTrigger(popupState)}
                                                                    >
                                                                        <ExpandMoreIcon
                                                                            style={{ color: "#141522" }}
                                                                        />
                                                                    </IconButton>
                                                                    <Menu {...bindMenu(popupState)}>
                                                                        {row?.status === "suspended" && (
                                                                            <Box>
                                                                                <MenuItem
                                                                                    onClick={() => {
                                                                                        setCurrentStatus("approved");
                                                                                        popupState.setOpen(false);
                                                                                        setReason(false);
                                                                                        updateAdminList(row.id, "approve");
                                                                                    }}
                                                                                >
                                                                                    Approve
                                                                                </MenuItem>
                                                                            </Box>
                                                                        )}
                                                                        {row?.status === "approved" && (
                                                                            <Box>
                                                                                <MenuItem
                                                                                    onClick={() => {
                                                                                        handleSuspendAction(row.id);
                                                                                        popupState.setOpen(false);
                                                                                    }}
                                                                                >
                                                                                    Suspend
                                                                                </MenuItem>
                                                                            </Box>
                                                                        )}
                                                                        {/* <Box>
                                                                            <MenuItem
                                                                                onClick={() => {
                                                                                    resetPassword(row.email);

                                                                                    // setCurrentStatus("approved");
                                                                                    // popupState.setOpen(false);
                                                                                    // setReason(false);
                                                                                    // updateAdminList(row.id, "approve");
                                                                                }}
                                                                            >{
                                                                                    resetPasswordApiStatus.isPending ? (
                                                                                        <CircularProgress size={10} style={{ color: 'black' }}></CircularProgress>
                                                                                    ) : (
                                                                                        "Reset Password"
                                                                                    )
                                                                                }

                                                                            </MenuItem>
                                                                        </Box> */}
                                                                    </Menu>

                                                                </React.Fragment>
                                                            )}
                                                        </PopupState>
                                                    </Box>
                                                </Box>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </Datatables>
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
};

export default AdminUsersTable;
