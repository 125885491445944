import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputLabel,
  Typography,
  useTheme
} from "@material-ui/core";
import { useFormik } from "formik";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import { apiClient } from "../../config/apiClient";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import TextField from "../../MuiComponent/TextField";
import { useAuth } from "../../providers/AuthProvider";
import { changePersonalDetails } from "../../validations/login-validate";

function ChangePassword({ profileData }) {
  const theme = useTheme();
  const globalClasses = useGeneralStyle();
  const [showCurrentPassword, setCurrentShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  const [showConfirmPassword, setConfirmShowPassword] = useState(false);

  const {
    values,
    touched,
    errors,
    handleBlur,
    submitForm,
    handleChange,
    ...rest
  } = useFormik({
    initialValues: {
      first_name: JSON.parse(window.localStorage.getItem('user')).first_name,
      last_name: JSON.parse(window.localStorage.getItem('user')).last_name,
    },
    validationSchema: changePersonalDetails,
    onSubmit: () => {
      changePassword();
    },
  });

  const apiSource = useCancelRequest();
  const changePasswordApiStatus = useCallbackStatus();
  const auth = useAuth();
  const notification = useSnackbar();

  const changePassword = async () => {
    try {
      const result = await changePasswordApiStatus.run(
        apiClient("POST", "admin", "manageadmin", {
          body: {
            role_id: auth.getUserId(),
            action: 'update',
            first_name: values.first_name,
            last_name: values.last_name,
          },
          shouldUseDefaultToken: false,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      const {
        content: { data },
      } = result;

      var details = JSON.parse(window.localStorage.getItem('user'))
      details.first_name = values.first_name;
      details.last_name = values.last_name;
      window.localStorage.setItem('user', JSON.stringify(details))

      notification.enqueueSnackbar("Details Change Successfully", {
        variant: "success",
      });
    } catch (err) {
      if (err && err.code === 403) {
        auth.logout();
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "err",
          autoHideDuration: 2000,
        });
      }
    }
  };

  return (
    <>
      <Box style={{ padding: theme.spacing(2) }}>
        {/* {JSON.stringify(values)} */}
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
            <Box display="flex" flexDirection="column">
              <InputLabel className={globalClasses.inputLabel}>
                First Name
              </InputLabel>
              <TextField
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                id="first_name"
                name="first_name"
                style={{ backgroundColor: "#fff", height: "44px" }}
                error={touched.first_name && errors.first_name}
                helperText={touched.first_name && errors.first_name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.first_name}
                placeholder="Enter you first_name"
                variant="outlined"
                type="text"
              // type={showCurrentPassword ? "text" : "password"}
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <LockIcon />
              //     </InputAdornment>
              //   ),
              //   endAdornment: (
              //     <IconButton
              //       aria-label="Toggle password visibility"
              //       onClick={() =>
              //         setCurrentShowPassword((prevValue) => !prevValue)
              //       }
              //     >
              //       {showCurrentPassword ? (
              //         <VisibilityOff
              //           style={{ color: "black" }}
              //           fontSize="small"
              //           className={globalClasses.icon}
              //         />
              //       ) : (
              //         <Visibility
              //           style={{ color: "black" }}
              //           fontSize="small"
              //           className={globalClasses.icon}
              //         />
              //       )}
              //     </IconButton>
              //   ),
              // }}
              />
            </Box>
          </Grid>
          <Grid item xs={12} md={8} style={{ marginBottom: "15px" }}>
            <Box display="flex" flexDirection="column">
              <InputLabel className={globalClasses.inputLabel}>
                Last Name
              </InputLabel>
              <TextField
                id="last_name"
                name="last_name"
                style={{ backgroundColor: "#fff", height: "44px" }}
                error={touched.last_name && errors.last_name}
                helperText={touched.last_name && errors.last_name}
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.last_name}
                FormHelperTextProps={{ style: { fontSize: "10px" } }}
                placeholder="Enter you last name"
                variant="outlined"
                // type={showConfirmPassword ? "text" : "password"}
                type="text"
              // InputProps={{
              //   startAdornment: (
              //     <InputAdornment position="start">
              //       <LockIcon />
              //     </InputAdornment>
              //   ),
              //   endAdornment: (
              //     <IconButton
              //       aria-label="Toggle password visibility"
              //       onClick={() =>
              //         setConfirmShowPassword((prevValue) => !prevValue)
              //       }
              //     >
              //       {showConfirmPassword ? (
              //         <VisibilityOff
              //           style={{ color: "black" }}
              //           fontSize="small"
              //           className={globalClasses.icon}
              //         />
              //       ) : (
              //         <Visibility
              //           style={{ color: "black" }}
              //           fontSize="small"
              //           className={globalClasses.icon}
              //         />
              //       )}
              //     </IconButton>
              //   ),
              // }}
              />
              {!(touched.last_name && errors.last_name) && (
                <Typography
                  variant="caption"
                  style={{
                    fontSize: "11px",
                    fontWeight: 450,
                  }}
                >
                  Password must contain at least 8 characters, one uppercase,
                  one lowercase, and one digit
                </Typography>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Button
        style={{
          position: "fixed",
          top: "7rem",
          right: "3rem",
          background: "#4379EE",
          color: "white",
        }}
        disableElevation
        variant="contained"
        disabled={changePasswordApiStatus.isPending ? true : false}
        onClick={submitForm}
      >
        {changePasswordApiStatus.isPending ? (
          <CircularProgress size={23} style={{ color: "#BAC8FF" }} />
        ) : (
          "Update"
        )}
      </Button>
    </>
  );
}

export default ChangePassword;
