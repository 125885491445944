import {
  Avatar,
  Box,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
// import Header from "../layouts/Header";
// import pencilIcon from "../assets/images/icons/edit-icon.svg";
// import galleryIcon from "../assets/images/icons/gallery.svg";
// import trashIcon from "../assets/images/icons/trash.svg";
import { ChevronRight as ChevronRightIcon } from "@mui/icons-material";
import ChangePassword from "./ChangePassword";
// import ProfileModal from "../MuiComponent/ProfileModal";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import { useAuth } from "../../providers/AuthProvider";
// import FilePicker from "../utils/FilePicker";
// import { awsFileUpload, awsLinkGenerate } from "../config/file-uploader";
// import { USER_TOKEN } from "../../config/constants";
import Skeleton from "@mui/material/Skeleton";

import { useSnackbar } from "notistack";
import TabPanel from "../../MuiComponent/TabPanel";
import { apiClient } from "../../config/apiClient";
import Header from "../../layouts/Header";
import PersonalDetails from "./PersonalDetails";

const useStyles = makeStyles((theme) => ({
  avatar: {
    height: theme.spacing(10),
    width: theme.spacing(10),
    margin: "15px 25px",
  },
  profileSideBar: { fontSize: "15px", fontWeight: 600 },
  avatarBox: {
    position: "absolute",
    margin: "0px",
    cursor: "pointer",
    // top: "82px",
    // left: "92px"
    // top: "218px",
    // left: "353px",
    top: "55%",
    left: "62%",
    [theme.breakpoints.down("md")]: {
      // top: "218px",
      // left: "355px"
    },
    [theme.breakpoints.down("sm")]: {
      // top: "208px",
      // left: "106px",
    },
  },
  leftuserdetailsprofile: {
    backgroundColor: "#fff",
    borderRight: "16px solid #F5F5F7",
    padding: " .7rem",
    color: "black",

    [theme.breakpoints.down("sm")]: {
      borderRight: "none",
    },
  },
  settingsOptionsListItemIcon: {
    minWidth: 0,
    color: "black",
  },
}));

const renderLoading = (data, height, width) => {
  if (data) {
    return data;
  } else {
    return (
      <Skeleton
        style={{
          width: `${width}px`,
          height: `${height}px`,
        }}
      />
    );
  }
};

// const ModalHeader = () => {
//   return (
//     <>
//       <Box textAlign="center" padding={1}>
//         Admin Profile Picture
//       </Box>
//     </>
//   );
// };

function ProfileManagement() {
  const notification = useSnackbar();
  const classes = useStyles();
  const [tab, setTab] = useState("personal-details");
  const [modal, setModal] = React.useState(false);
  const auth = useAuth();
  const getUserByIdApi = useCallbackStatus();
  const apiSource = useCancelRequest();
  let [userProfile, setProfileData] = useState(JSON.parse(window.localStorage.getItem('user')));
  let [editInstance, setEditInstance] = useState({});
  const updateProfileApiStatus = useCallbackStatus();
  const [profileUploadingLoader, setProfileUploadingLoader] = useState(false);

  const getUserById = async () => {
    try {
      const result = await getUserByIdApi.run(
        apiClient("POST", "admin", "getadminbyfilter", {
          body: {
            admin_id: auth.getUserId(),
          },
          shouldUseDefaultToken: false,
          cancelToken: apiSource.token,
          enableLogging: true,
        })
      );

      const {
        content: { data },
      } = result;

      console.log(">>>>>>", data);
      setProfileData(data[0]);
      setEditInstance(data);
    } catch (err) {
      if (err && err.code === 403) {
        auth.logout();
      } else {
        notification.enqueueSnackbar(err.message, {
          variant: "err",
          autoHideDuration: 2000,
        });
      }
    }
  };

  console.log("userprofile", userProfile);

  // const fileHandler = async (file, alt) => {
  //   userProfile = {
  //     ...userProfile,
  //     profilePicturePath: file,
  //   };

  //   setProfileData(userProfile);
  // };

  // const updateProfileHandler = async () => {
  //   let result = {};

  //   setProfileUploadingLoader(true);

  //   if (typeof userProfile.profilePicturePath == "string") {
  //     result = await updateProfileApiStatus.run(
  //       apiClient("POST", "admin", "updateadminprofile", {
  //         body: {
  //           admin_id: auth.getUserId(),
  //           profile_url: null,
  //         },
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //         .then((result) => {
  //           const {
  //             content: { data },
  //           } = result;

  //           data.secret = window.localStorage.getItem(USER_TOKEN);
  //           getUserById();
  //           setModal(!modal);
  //           setProfileUploadingLoader(false);
  //           let active_page = JSON.parse(
  //             window.localStorage.getItem("activePages")
  //           );

  //           data.active_pages = active_page.map((page) => ({ page }));
  //           auth.setUser(data);
  //         })
  //         .catch((err) => {
  //           if (err && err.code === 403) {
  //             auth.logout();
  //           } else {
  //             notification.enqueueSnackbar(err.message, {
  //               variant: "err",
  //               autoHideDuration: 2000,
  //             });
  //           }
  //         })
  //     );
  //   } else if (typeof userProfile.profilePicturePath == "undefined") {
  //     setModal(!modal);
  //     setProfileUploadingLoader(false);
  //   } else {
  //     let signedObject = await awsLinkGenerate({
  //       file: userProfile.profilePicturePath,
  //       type: "image",
  //       awsFolderPath: "admin/profilePictures",
  //       userId: auth.getUserId(),
  //     });
  //     let isUploaded = await awsFileUpload({
  //       signedUrl: signedObject.signedUrl,
  //       file: signedObject.file,
  //     });

  //     if (isUploaded.status === 200) setProfileUploadingLoader(false);

  //     result = await updateProfileApiStatus.run(
  //       apiClient("POST", "admin", "updateadminprofile", {
  //         body: {
  //           admin_id: auth.getUserId(),
  //           profile_url: signedObject.signedUrl.split("?")[0],
  //         },
  //         shouldUseDefaultToken: false,
  //         cancelToken: apiSource.token,
  //         enableLogging: true,
  //       })
  //         .then((result) => {
  //           const {
  //             content: { data },
  //           } = result;
  //           console.log("PROFILE UPDATED SUCCESSFULLY");

  //           data.secret = window.localStorage.getItem(USER_TOKEN);
  //           setProfileUploadingLoader(false);
  //           let active_page = JSON.parse(
  //             window.localStorage.getItem("activePages")
  //           );

  //           data.active_pages = active_page.map((page) => ({ page }));

  //           auth.setUser(data);
  //           getUserById();
  //           setModal(!modal);
  //           setProfileUploadingLoader(false);
  //         })
  //         .catch((err) => {
  //           setProfileUploadingLoader(false);
  //           if (err && err.code === 403) {
  //             auth.logout();
  //           } else {
  //             notification.enqueueSnackbar(err.message, {
  //               variant: "err",
  //               autoHideDuration: 2000,
  //             });
  //           }
  //         })
  //     );
  //   }
  // };

  const renderImage = () => {
    if (userProfile.profilePicturePath) {
      if (typeof userProfile.profilePicturePath !== "string")
        return URL.createObjectURL(userProfile.profilePicturePath);
    } else return userProfile.profile_url;
  };

  // useEffect(() => {
  //   getUserById();
  // }, []);

  return (
    <>
      {/* <ProfileModal
        height="340px"
        width="340px"
        loading={profileUploadingLoader || updateProfileApiStatus.isPending}
        headerComponent={ModalHeader}
        saveBtnText="Done"
        closeBtnText="Cancel"
        buttonAlign="center"
        closeHandler={() => {
          setModal(!modal);
        }}
        saveHandler={updateProfileHandler}
        status={modal}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Box>
            <Avatar
              src={renderImage()}
              className={`${classes.avatar} headerAvatar`}
            />
          </Box>
          <Box display="flex">
            <Box
              style={{ borderRight: "2px solid #858593", paddingRight: "10px" }}
            >
              <IconButton
                onClick={() => fileHandler("deleted")}
                size="small"
                style={{ paddingLeft: "10px" }}
              >
                <img height="20px" src={trashIcon} />
              </IconButton>
            </Box>
            <Box style={{ paddingLeft: "10px" }}>
              <FilePicker
                multiple={false}
                filetype="image"
                fileHandler={fileHandler}
                type="iconButton"
                icon={galleryIcon}
              />
            </Box>
          </Box>
        </Box>
      </ProfileModal> */}

      {/* <Header>
      </Header> */}
      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          My Profile
        </Typography>
      </Header>
      <br />

      <Box style={{ padding: "12px", color: "white" }}>
        <Box style={{ padding: "12px 0px" }}>
          {/* <Button style={{ color: "black" }} onClick={() => {}}>
            <ArrowBackRoundedIcon style={{fontSize:"1rem", marginRight:".5rem"}} /> Back
          </Button> */}
        </Box>

        <Grid container>
          <Grid item xs={12} container>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: "#fff", marginBottom: "20px" }}
            >
              <Box display="flex" alignItems="center">
                <Box style={{ position: "relative" }}>
                  <Avatar
                    src={userProfile.profile_url}
                    style={{ backgroundColor: '#4379EE' }}
                    className={`${classes.avatar} headerAvatar`}
                  />
                  {/* <img
                    alt=""
                    className={classes.avatarBox}
                    src={pencilIcon}
                    onClick={() => {
                      setProfileData(editInstance);
                      setModal(!modal);
                    }}
                  /> */}
                </Box>
                <Box display="flex" flexDirection="column">
                  <Box
                    component="span"
                    style={{
                      fontSize: "17px",
                      fontWeight: 700,
                      textTransform: "uppercase",
                      color: "black",
                    }}
                  >
                    {renderLoading(userProfile.first_name, 30, 150)}{" "}
                    {renderLoading(userProfile.last_name, 30, 150)}
                    {/* getUserByIdApi */}
                  </Box>
                  <Box component="span" style={{ color: "gray" }}>
                    {renderLoading(userProfile.email, 30, 100)}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid container item style={{ boxSizing: "border-box" }}>
              <Grid
                className={classes.leftuserdetailsprofile}
                item
                md={3}
                // md={12}
                xs={12}
                sm={12}
              >
                <List disablePadding>
                  <ListItem
                    button
                    selected={tab === "personal-details"}
                    onClick={() => setTab("personal-details")}
                    style={{ background: "white", borderRadius: "5px" }}

                  >
                    <ListItemText
                      // classes={{ body1: classes.profileSideBar }}
                      primary="Personal Details"
                    />
                    <ListItemIcon
                      className={classes.settingsOptionsListItemIcon}
                    >
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItem>
                  <ListItem
                    button
                    selected={tab === "change-password"}
                    onClick={() => setTab("change-password")}
                    style={{ background: "white", borderRadius: "5px" }}
                  >
                    <ListItemText primary="Change Password" />
                    <ListItemIcon
                      className={classes.settingsOptionsListItemIcon}
                    >
                      <ChevronRightIcon />
                    </ListItemIcon>
                  </ListItem>
                </List>
              </Grid>

              <Grid
                item
                md={9}
                // md={12}
                xs={12}
                sm={12}
                style={{ backgroundColor: "#fff", height: "55vh" }}
              >
                <TabPanel value={tab} index="personal-details">
                  <PersonalDetails
                    loading={getUserByIdApi.isPending}
                    refresh={getUserById}
                    profileData={userProfile}
                  />
                </TabPanel>
                <TabPanel value={tab} index="change-password">
                  <ChangePassword />
                </TabPanel>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </>
  );
}

export default ProfileManagement;
