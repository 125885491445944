import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import Header from "../../layouts/Header"


import {
  Box,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Select,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import _ from "lodash";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import moment from "moment";
import { SnackbarProvider, enqueueSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import useGeneralStyle from "../../assets/css/general";
import Datatables from "../../components/Datatables";
import { apiClient } from "../../config/apiClient";
import { STATUS_COLOR } from "../../config/constants";
import useCallbackStatus from "../../hooks/useCallbackStatus";
import useCancelRequest from "../../hooks/useCancelToken";
import ReasonModal from "./ReasonModal";

const tableHeaderSpecialty = [
  { id: "date", align: "left", label: "CREATED AT", minWidth: 100 },
  { id: "date", align: "left", label: "PROFESSION NAME", minWidth: 100 },
  { id: "date", align: "left", label: "SPECIALTY", minWidth: 100 },
  { id: "date", align: "left", label: "REQUESTED BY", minWidth: 100 },
  { id: "date", align: "left", label: "EMAIL", minWidth: 100 },
  { id: "date", align: "left", label: "PHONE NO", minWidth: 100 },

  { id: "date", align: "left", label: "STATUS", minWidth: 100 },
];
const tableHeaderRejectedSpecialty = [
  { id: "date", align: "left", label: "CREATED AT", minWidth: 100 },
  { id: "date", align: "left", label: "PROFESSION NAME", minWidth: 100 },
  { id: "date", align: "left", label: "SPECIALTY", minWidth: 100 },
  { id: "date", align: "left", label: "REQUESTED BY", minWidth: 100 },
  { id: "date", align: "left", label: "EMAIL", minWidth: 100 },
  { id: "date", align: "left", label: "PHONE NO", minWidth: 100 },
  { id: "date", align: "left", label: "ADMIN COMMENT", minWidth: 100 },
  { id: "date", align: "left", label: "STATUS", minWidth: 100 },
];
const tableHeaderSubSpecialty = [
  { id: "date", align: "left", label: "CREATED AT", minWidth: 100 },
  { id: "date", align: "left", label: "PROFESSION NAME", minWidth: 100 },
  { id: "date", align: "left", label: "SPECIALTY", minWidth: 100 },
  { id: "date", align: "left", label: "SUB-SPECIALTY", minWidth: 100 },
  { id: "date", align: "left", label: "REQUESTED BY", minWidth: 100 },
  { id: "date", align: "left", label: "EMAIL", minWidth: 100 },
  { id: "date", align: "left", label: "PHONE NO", minWidth: 100 },
  { id: "date", align: "left", label: "STATUS", minWidth: 100 },
];
const tableHeaderRejectedSubSpecialty = [
  { id: "date", align: "left", label: "CREATED AT", minWidth: 100 },
  { id: "date", align: "left", label: "PROFESSION NAME", minWidth: 100 },
  { id: "date", align: "left", label: "SPECIALTY NAME", minWidth: 100 },
  { id: "date", align: "left", label: "SUB-SPECIALTY", minWidth: 100 },
  { id: "date", align: "left", label: "REQUESTED BY", minWidth: 100 },
  { id: "date", align: "left", label: "EMAIL", minWidth: 100 },
  { id: "date", align: "left", label: "PHONE NO", minWidth: 100 },
  { id: "date", align: "left", label: "ADMIN COMMENT", minWidth: 100 },

  { id: "date", align: "left", label: "STATUS", minWidth: 100 },
];

let diffTableHeader = [...tableHeaderSpecialty];
diffTableHeader.splice(4, 1);

const useStyles = makeStyles((theme2) => ({
  formControl: {
    margin: theme2.spacing(1),
    minWidth: 160,
    color: "white",
    borderBottom: "none",
  },
  selectEmpty: {
    marginTop: theme2.spacing(2),
  },
  statusBox: {
    width: "100px",
    borderRadius: "5px",
    padding: "5px",
    textAlign: "center",
    fontWeight: "bold",
  },
  icon: {
    fill: "white",
  },
  root: {
    width: "100%",
    overflowX: "auto",
    // marginTop: '25px',
  },
  suspendedStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #fd6868",
    backgroundColor: "#ffc3c3",
    color: "#e64848",
    fontWeight: "bold",
  },
  rejectedStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #fd6868",
    backgroundColor: "#ffc3c3",
    color: "#e64848",
    fontWeight: "bold",
  },
  pendingStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #eab20a",
    backgroundColor: "#ffeeb5",
    color: "#eab20a",
    fontWeight: "bold",
  },
  registeredStatus: {
    borderRadius: "5px",
    padding: "5px 30px",
    border: "1px solid #6ac358",
    backgroundColor: "#c3f4ba",
    color: "#6ac358",
    fontWeight: "bold",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  activeFilter: {
    // backgroundColor: theme2.palette.primary.light,
    borderBottom: "2px solid white ",
    borderRadius: "0px",
    color: "white",
    fontWeight: 600,
  },
  icon: {
    fill: "#FFFFFF",
  },
  carddelModal: {
    width: "500px",
    borderRadius: "12px",
    backgroundColor: "#1b1f3b",
    padding: "1rem",
    position: "relative",

    [theme2.breakpoints.down("sm")]: {
      width: "90vw !important",
    },

    "&:focus-visible": {
      outline: "none",
    },
  },
  profileData: {
    color: "white",
    paddingTop: "2px",
    fontSize: "16px",
    // fontWeight: 600,
  },
  profileHeading: {
    fontSize: "12px",
    // fontWeight: 600,
    color: "#B0B2B6",
    textAlign: "left",
  },

  parentofappointmentcontent: {
    [theme2.breakpoints.down("md")]: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
    },
  },
  bottombox: {
    [theme2.breakpoints.down("xs")]: {
      marginLeft: "0 !important",
      display: "flex",
      justifyContent: "flex-start !important",
      alignItems: "flex-start !important",
    },
  },
  doublerarrow: {
    [theme2.breakpoints.down("xs")]: {
      transform: "rotate(90deg)",
      margin: "1rem !important",
    },
  },

  tabsContainer: {
    backgroundColor: "#fff",
    margin: "20px 20px 0px 20px",
    height: "44px",
    [theme2.breakpoints.down("md")]: {
      width: "95%",
      overflow: "scroll",
    },
    [theme2.breakpoints.down("sm")]: {
      width: "88%",
      overflow: "scroll",
    },
    "&::-webkit-scrollbar": {
      height: "0",
    },
  },
}));
const AdminUsersTable = () => {
  const globalClasses = useGeneralStyle();
  const classes = useStyles();
  const [reason, setReason] = useState(false);
  const getTicketApiStatus = useCallbackStatus();
  const apiSource = useCancelRequest();
  const [adminsList, setAdminList] = useState([]);
  const [tableHead, setTableHead] = useState(tableHeaderSpecialty);
  const [admin_comment, setAdminComment] = useState("");
  const [search, setSearchKey] = useState("");
  const [rowId, setRowId] = useState(null);
  const [currentStatus, setCurrentStatus] = useState("");
  const [hasMore, setHasMore] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [filter, setFilter] = useState({
    search: "",
    status: "pending",
    type: "specialty",
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getAllTickets(null, true);
    }, 300);

    return () => clearTimeout(delayDebounceFn);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  let getAllTickets = async (token, refresh, updateStatus) => {
    let apiBody = {};
    if (!updateStatus) {
      apiBody = {
        action: "list",
        status: "rejected",
        pagination_required: true,
        next_token: token,
        label_type: filter.type,
      };
      if (!token) {
        delete apiBody.next_token;
      }
      if (filter.status != "none") {
        apiBody.status = filter.status;
      }
      if (search) {
        apiBody.search_string = search;
        apiBody.search_fields = [
          "admin_comment",
          "user_comment",
          "reason",
          "phone_number",
        ];
      }
    } else {
      apiBody = {
        action: "update",
        label_id: rowId,
        status: updateStatus,
        label_type: filter.type,
      };
      if (updateStatus === "rejected") {
        apiBody.comment = admin_comment;
      }
    }
    console.log("My body");
    console.log(apiBody);
    await getTicketApiStatus.run(
      apiClient("POST", "admin", "managerequestedlabel", {
        body: { ...apiBody },
        shouldUseDefaultToken: false,
        cancelToken: apiSource.token,
        enableLogging: true,
      })
        .then((result) => {
          const {
            content: { data, has_more, next_token },
          } = result;
          if (updateStatus) {
            setRowId(null);
            setCurrentStatus(null);
            getAllTickets(null, true);
            enqueueSnackbar("Updated Successfully", {
              variant: "success",
            });
          } else {
            if (refresh) setAdminList([...data]);
            else {
              setAdminList([...adminsList, ...data]);
            }
            setHasMore(has_more);
            setTotalPages(next_token);
          }
          console.log(result);
        })
        .catch((error) => {
          enqueueSnackbar(error.message, {
            variant: "error",
          });
        })
    );
  };

  useEffect(() => {
    getAllTickets(null, true);
  }, [filter.status]);

  useEffect(() => {
    getAllTickets(null, true);
  }, [filter.type]);

  useEffect(() => {
    if (rowId && currentStatus) {
      getAllTickets(null, true, currentStatus);
    }
  }, [currentStatus]);
  // const adminsList = [
  //   {
  //     id: "9afc4b9a-31f8-44b3-98af-83bc0c3aa26f",
  //     phone_number: "8160623323",
  //     reason: "Not able to login",
  //     user_comment: "I request you to help me here as i am not able to login.",
  //     admin_comment: "Sorry, We can not help you here",
  //     status: "rejected",
  //     created_at: 1702350412,
  //     created_by_name: "Diskah",
  //   },
  //   {
  //     id: "9afc4b9a-31f8-44b3-98af-83bc0c3aa26f",
  //     phone_number: "8160623323",
  //     reason: "Not able to login",
  //     user_comment: "I request you to help me here as i am not able to login.",
  //     admin_comment: "Sorry, We can not help you here",
  //     status: "pending",
  //     created_at: 1702350412,
  //     created_by_name: "priya",
  //   },
  // ];
  return (
    <>
      <Header />
      <Grid container>
        <SnackbarProvider />
        {reason ? (
          <ReasonModal
            setReason={setReason}
            setAdminComment={setAdminComment}
            getAllTickets={getAllTickets}
            admin_comment={admin_comment}
            setCurrentStatus={setCurrentStatus}
          />
        ) : null}
        <Grid
          container
          style={{ backgroundColor: "white", paddingLeft: "20px" }}
          className="ChangeRequestsContainer"
        >
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
            item
            xs={11}
            sm={5}
            md={5}
            lg={5}
          >
            <TextField
              onChange={(e) => setSearchKey(e.target.value)}
              variant="outlined"
              style={{ backgroundColor: "#F5F5F7", width: "400px" }}
              inputProps={{
                style: { padding: "12px" },
              }}
              InputProps={{
                startAdornment: (
                  <SearchIcon
                    variant="filled"
                    fontSize="small"
                    style={{ backgroundColor: "#F5F5F7", color: "#141522" }}
                  />
                ),
                className: "inputFont",
              }}
              placeholder="Search"
            />
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              padding: "10px 0px",
            }}
            item
            xs={11}
            sm={5}
            md={5}
            lg={5}
          >
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              variant="outlined"
              value={filter.type}
              onChange={(e) => {
                setFilter({ ...filter, type: e.target.value });
                if (
                  e.target.value === "specialty" &&
                  filter.status === "rejected"
                ) {
                  setTableHead(tableHeaderRejectedSpecialty);
                } else if (
                  e.target.value === "sub_specialty" &&
                  filter.status === "rejected"
                ) {
                  setTableHead(tableHeaderRejectedSubSpecialty);
                } else if (
                  e.target.value === "specialty" &&
                  filter.status != "rejected"
                ) {
                  setTableHead(tableHeaderSpecialty);
                } else if (
                  e.target.value === "sub_specialty" &&
                  filter.status != "rejected"
                ) {
                  setTableHead(tableHeaderSubSpecialty);
                }
              }}
              style={{
                marginLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "#F5F5F7",
                width: "40%",
              }}
              className={globalClasses.selectInput}
            >
              <MenuItem key="specialty" default selected value="specialty">
                Specialty
              </MenuItem>
              <MenuItem
                key="sub-specialty"
                default
                selected
                value="sub_specialty"
              >
                Sub-Specialty
              </MenuItem>
            </Select>
            <Select
              inputProps={{
                classes: {
                  icon: classes.icon,
                },
              }}
              variant="outlined"
              value={filter.status}
              onChange={(e) => {
                setFilter({ ...filter, status: e.target.value });
                if (
                  e.target.value === "rejected" &&
                  filter.type === "specialty"
                ) {
                  setTableHead(tableHeaderRejectedSpecialty);
                } else if (
                  e.target.value === "rejected" &&
                  filter.type === "sub_specialty"
                ) {
                  setTableHead(tableHeaderRejectedSubSpecialty);
                } else if (
                  e.target.value != "rejected" &&
                  filter.type === "specialty"
                ) {
                  setTableHead(tableHeaderSpecialty);
                } else if (
                  e.target.value != "rejected" &&
                  filter.type === "sub_specialty"
                ) {
                  setTableHead(tableHeaderSubSpecialty);
                }
              }}
              style={{
                marginLeft: "20px",
                paddingRight: "20px",
                backgroundColor: "#F5F5F7",
                width: "40%",
              }}
              className={globalClasses.selectInput}
            >
              <MenuItem key="pending" default selected value="pending">
                Pending
              </MenuItem>
              <MenuItem key="approved" default selected value="approved">
                Approved
              </MenuItem>
              <MenuItem key="rejected" default selected value="rejected">
                Rejected
              </MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid item xs={12} style={{ paddingTop: "20px" }}>
          <Paper className={classes.root}>
            <Datatables
              data={adminsList}
              loading={getTicketApiStatus.isPending}
              getDataList={(token) => getAllTickets(token, false)}
              hasMore={hasMore}
              nextToken={totalPages}
              tableHeader={tableHead}
              orderByKey="name"
              isEmpty={adminsList.length === 0}
            >
              {adminsList.map((row, index) => {
                return (
                  <TableRow key={index} hover>
                    <TableCell>
                      <Typography>
                        {moment
                          .unix(row.created_at)
                          .format("Do MMM YYYY, hh:mm A")}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row.profession_name || "-"}
                      </Typography>
                    </TableCell>
                    {filter.type === "sub_specialty" ? (
                      <TableCell>
                        <Typography>
                          {row.specialty_name}
                        </Typography>
                      </TableCell>
                    ) : null}
                    {filter.type === "sub_specialty" ? (
                      <TableCell>
                        <Typography>
                          {row.name}
                        </Typography>
                      </TableCell>
                    ) : null}
                    {filter.type != "sub_specialty" ? (
                      <TableCell>
                        <Typography>
                          {row.name}
                        </Typography>
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <Typography>
                        {row.first_name + " " + row.last_name}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row.email || "-"}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography>
                        {row.phone_number || "-"}
                      </Typography>
                    </TableCell>

                    {filter.status === "rejected" ? (
                      <TableCell>
                        <Typography>
                          {row.admin_comment || "-"}
                        </Typography>
                      </TableCell>
                    ) : null}
                    <TableCell>
                      <Box display="flex" alignItems="center">
                        <Box
                          style={{
                            backgroundColor: STATUS_COLOR[filter.status],
                          }}
                          className={classes.statusBox}
                        >
                          <Typography style={{ color: "white" }}>
                            {_.upperCase(
                              filter.status === "approved"
                                ? "Approved"
                                : filter.status === "rejected"
                                  ? "Rejected"
                                  : "Pending"
                            )}
                          </Typography>
                        </Box>
                        <Box>
                          {filter.status === "pending" && (
                            <PopupState
                              variant="popover"
                              popupId="demo-popup-menu"
                            >
                              {(popupState) => (
                                <React.Fragment>
                                  <IconButton
                                    size="small"
                                    {...bindTrigger(popupState)}
                                  >
                                    <ExpandMoreIcon
                                      style={{ color: "#141522" }}
                                    />
                                  </IconButton>
                                  <Menu {...bindMenu(popupState)}>
                                    {filter?.status === "pending" && (
                                      <Box>
                                        <MenuItem
                                          onClick={() => {
                                            setRowId(row?.id);
                                            setCurrentStatus("approved");
                                            popupState.setOpen(false);
                                          }}
                                        >
                                          Approve
                                        </MenuItem>
                                        <MenuItem
                                          onClick={() => {
                                            setReason(true);
                                            setRowId(row.id);
                                            // setCurrentStatus("rejected");
                                            //   setUserObject(row);
                                            //   setModal(!modal);
                                            popupState.setOpen(false);
                                          }}
                                        >
                                          Reject
                                        </MenuItem>
                                      </Box>
                                    )}
                                  </Menu>
                                </React.Fragment>
                              )}
                            </PopupState>
                          )}
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </Datatables>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default AdminUsersTable;
